import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from "@material-ui/core/MenuItem";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';
import icon48 from "../images/Icon-48.png";

import './topbar.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Lato', // aplicar fonte Lato no toolbar
    fontWeight: "bold", // negrito
    textTransform: 'none', // tirar o UPPERCASE dos botoes do toolbar
    color: theme.palette.background.default
  },
  appBarStyle: {
    backgroundColor: theme.palette.primary.main, // aplicar fundo branco no toolbar
    boxShadow: 'none', // remover a linha em branco embaixo do toolbar
  },
  toolbar: {
    backgroundColor: 'white',
  },
  iconSpace: {
    marginRight: "10px"
  },
  drawerContainer: {
    padding: "20px 30px",
  },
}));

export default function Topbar() {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  const classes = useStyles();

  useEffect(() => {
    const setResponsiveness = () => window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const headersData = [
    {
      label: "Audio para Texto",
      href: "/audio-para-texto",
    },
    {
      label: "Transcrição",
      href: "/transcricao",
    },
    {
      label: "Tradução",
      href: "/traducao",
    },
    {
      label: "Preços",
      href: "/precos",
    },
    {
      label: "Login",
      href: "/login",
    },
  ];

  const getDrawerChoices = () => headersData.map(({ label, href }) => (
    // <Link to="/precos"><Button className="menu" color="inherit">Preços</Button></Link>

    <Link
      {...{
        // component: RouterLink,
        to: href,
        // color: "inherit",
        className: "menuDrawer",
        // style: { textDecoration: "none" },
        key: label,
      }}
    >
      <MenuItem className="menuDrawer">{label}</MenuItem>
    </Link>
  ));

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <Typography variant="h6" className={classes.title}>
          PREALT
        </Typography>
      </Toolbar>
    )
  }

  const displayDesktop = () => (
    <Container>
          <Toolbar>
            <Link to="/"><img src={icon48} alt="icon" className={classes.iconSpace} /></Link>
            <Typography variant="h6" className={classes.title}>
              PREALT - O POLIGLOTO EM TEMPO REAL
            </Typography>
            <Link to="/audio-para-texto"><Button className="menu" color="inherit">Audio para Texto</Button></Link>
            <Link to="/transcricao"><Button className="menu" color="inherit">Transcrição</Button></Link>
            <Link to="/traducao"><Button className="menu" color="inherit">Tradução</Button></Link>
            <Link to="/precos"><Button className="menu" color="inherit">Preços</Button></Link>
            <Link to="/login"><Button className="menu" color="inherit">Login</Button></Link>
          </Toolbar>
        </Container>
    )

    
  return (
    <header className={classes.root}>
      <AppBar className={classes.appBarStyle}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
